<template>
  <div>
    <el-dialog
      :title="title"
      width="600px"
      :close-on-click-modal="false"
      :visible.sync="dialogFormVisible"
      custom-class="customW"
      :before-close="handleClose"
    >
      <el-form :model="form" size="small" label-width="100px">
        <el-form-item label="接口名称：">
          <el-input v-model="form.route_name" placeholder="请输入接口名称" clearable style="width:60%"></el-input>
        </el-form-item>
        <el-form-item label="接口路径：">
          <el-input v-model="form.route_url" placeholder="请输入接口路径" clearable style="width:60%"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="close()" size="small">取 消</el-button>
        <el-button type="primary" @click="confirm()" size="small">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "portModal",
  data() {
    return {
      dialogFormVisible:false,
      title:"新增接口",
      form:{}
    };
  },
  methods: {
    async isShow(val,type) {
      this.dialogFormVisible = true
      if(type == "edit"){
        this.title = "修改接口"
        this.form.id = val.id
        this.form.route_name = val.route_name
        this.form.route_url = val.route_url
      } else {
        this.title = "新增接口"
      }
    },
    confirm(){
      console.log("确定",this.form)
    },
    close(){
      this.dialogFormVisible = false
      this.form = {}
    },
    handleClose(done){
      done();
      this.close()
    },
  }
};
</script>

<style lang="scss" scoped>
/deep/ .customW{
  border-radius: 10px !important;
  .el-dialog__header {
    border-bottom: 1px solid #e8eaec;
    .el-dialog__title{
      font-size: 14px;
      color: #17233d;
      font-weight: 700;
    }
  }
  .el-dialog__footer{
    border-top: 1px solid #e8eaec;
  }
}

</style>
