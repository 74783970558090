<template>
  <div class="businessPortList">
    <!-- <div class="derive">
      <el-button size="small" @click="createPost()">新增接口</el-button>
    </div> -->
    <div class="auth-table">
      <el-table :data="tableData" header-cell-class-name="table_header" row-key="id" border>
        <el-table-column prop="route_name" label="接口名称"/>
        <el-table-column prop="route_url" label="接口路径"/>
        <!-- <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="editPort(scope.row)">编辑</el-button>
          </template>
        </el-table-column> -->
      </el-table>
    </div>
    <port-modal ref="portModal"/>
  </div>
</template>

<script>
import portModal from './components/business-port-modal.vue';
import {businessRouteList} from '@/api/businessManage.js';
export default {
  name: 'businessPortList',
  components: { portModal },
  data() {
    return {
      tableData:[],
      searchForm:{
        routeName:''
      }
    };
  },

  mounted() {
    this.initData()
  },

  methods: {
    async initData(){
      const {code,data} = await businessRouteList(this.searchForm)
      if(code == 200){
        this.tableData = data
      }
    },
    createPost(){
      this.$refs.portModal.isShow()
    },
    editPort(row){
      this.$refs.portModal.isShow(row,"edit")
    }
  },
};
</script>

<style lang="scss" scoped>
.businessPortList{
  .derive{
    margin-bottom: 10px;
  }  
  .auth-table{
    /deep/ .table_header{
      background-color: #f8f8f9 !important;
      color: #515a6e !important;
    }
  }
}
</style>